var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[_c('CCardHeader',{staticClass:"bg-yellow text-black"},[_vm._v("ผลการเรียนจำแนกตามรายวิชา")]),(_vm.rows)?_c('CCardBody',[_c('SltBatch'),_c('div',{staticClass:"text-right",staticStyle:{"margin-bottom":"20px"}},[_c('button',{staticClass:"btn btn-yellow",on:{"click":_vm.getEnrollment}},[_vm._v("ค้นหา")])]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{ enabled: true },"sort-options":{
        enabled: true,
        initialSortBy: [
          { field: 'level_abv_name', type: 'asc' },
          { field: 'std_class', type: 'asc' },
        ],
      },"pagination-options":{ enabled: true, mode: 'page', perPage: 10 }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'stud_id')?_c('span',[_c('router-link',{attrs:{"to":'/statByStudent/' + props.row.stud_id}},[_vm._v(" "+_vm._s(props.row.stud_id)+" ")])],1):(props.column.field == 'actions')?_c('span',[_c('CDropdown',{scopedSlots:_vm._u([{key:"toggler",fn:function(){return [_c('CHeaderNavLink',[_c('button',{staticClass:"btn btn-secondary btn-block"},[_vm._v("Tools")])])]},proxy:true}],null,true)},[(props.row.enroll_who_create == parseInt(_vm.userData.user_id))?_c('CDropdownItem',{staticClass:"bg-danger text-white"},[_c('div',{staticClass:"text-center",staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.removeEnrollment(props.row.enroll_id)}}},[_vm._v(" delete ")])]):_vm._e()],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,2311711963)},[_vm._v(" > ")]),_c('CRow',{staticStyle:{"margin-top":"20px"}},[_c('CCol',[_c('div',{staticStyle:{"text-align":"right"}},[_c('button',{staticClass:"btn btn-secondary",on:{"click":_vm.onExport}},[_vm._v("Export")])])])],1)],1):_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }