<template>
  <div>
    <CCard>
      <CCardHeader class="bg-yellow"
        >สรุปความถี่ และชั่วโมง ที่เกี่ยวกับคะแนนพฤติกรรม
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol lg="4">
            <SltCriteria />
          </CCol>
          <CCol lg="4">
            <SltSemester />
          </CCol>
          <CCol lg="4">
            <SltSubject />
          </CCol>
          <CCol lg="12">
            <div style="text-align: right; margin-bottom: 20px">
              <button @click="getData" class="btn btn-yellow">ค้นหา</button>
              <!-- เพิ่มปุ่ม Export -->
            </div>
          </CCol>
        </CRow>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{ enabled: true }"
          :sort-options="{
            enabled: true,
            initialSortBy: { field: 'sum_hours', type: 'desc' },
          }"
          :pagination-options="{ enabled: true, mode: 'page', perPage: 10 }"
          v-if="rows"
        >
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'stud_id'">
              <router-link :to="'/statByStudent/' + props.row.stud_id">
                {{ props.row.stud_id }}
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'subject_name'">
              <router-link :to="'/statBySubject/' + props.row.subject_id">
                {{ props.row.subject_name }}
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'user_fname'">
              <router-link :to="'/statByTeacher/' + props.row.teacher_id">
                {{ props.row.user_fname }}
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'tc_citeria_name'">
              <router-link :to="'/statByReason/' + props.row.tc_citeria_no">
                {{ props.row.tc_citeria_name }}
              </router-link>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <div slot="emptystate">This will show up when there are no rows</div>
        </vue-good-table>
        <div v-else>
          <div class="spinner-border text-info" role="status">
            <span class="sr-only"></span>
          </div>
          Loading...
        </div>
        <CRow style="margin-top: 20px">
          <CCol>
            <div style="text-align: right">
              <button @click="onExport" class="btn btn-secondary">
                Export
              </button>
              <!-- เพิ่มปุ่ม Export -->
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <!-------MODAL EDIT----------------------------------------->
    <form
      id="formUpdateAbs"
      v-on:submit.prevent="updateData"
      method="POST"
      v-if="selectedAttId"
    >
      <CModal
        :show.sync="darkModal"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="dark"
      >
        <CRow>
          <!--body-->
          <CCol lg="6" v-if="selectedData">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              #
            </label>
            <input
              type="text"
              name="txtAttId"
              class="form-control"
              readonly
              v-model="selectedData.att_id"
            />
          </CCol>
          <CCol lg="6" v-if="selectedData">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              วันที่
            </label>
            <datepicker
              name="txtDate"
              input-class="form-control"
              :required="true"
              format="yyyy-MM-dd"
              placeholder="วันที่จัดกิจกรรม"
              v-model="selectedData.abs_date"
            ></datepicker>
          </CCol>
          <CCol lg="12">
            <SltCriteria />
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">
            <h3 class="text-3xl font-semibold">แก้ไข</h3>
          </h6>
          <CButtonClose @click="darkModal = false" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="darkModal = false" color="danger">Discard</CButton>
          <CButton @click="updateData" color="info">Save</CButton>
        </template>
      </CModal>
    </form>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import XLSX from "xlsx"; // import xlsx;
import SltSubject from "./SltSubject.vue";
import SltCriteria from "./SltCriteria.vue";
import SltSemester from "./SltSemester.vue";

export default {
  name: "Dashboard",
  components: {
    VueGoodTable,
    Datepicker,
    XLSX,
    SltSubject,
    SltCriteria,
    SltSemester,
  },
  data() {
    return {
      columns: [
        {
          label: "เลขประจำตัวนักเรียน",
          field: "stud_id",
        },
        {
          label: "ชื่อ",
          field: "std_name",
        },
        {
          label: "นามสกุล",
          field: "std_surname",
        },
        {
          label: "ชั้น",
          field: "level_abv_name",
        },
        {
          label: "ห้อง",
          field: "std_class",
          type:"number"
        },
        {
          label: "รวม (คะแนน)",
          field: "sum_score",
          type:"number"
        },
        {
          label: "จำนวน (ครั้ง)",
          field: "count_att_by_std_reason_semester_subject",
          type:"number"
        },
        {
          label: "จำนวน (ชั่วโมง)",
          field: "sum_hours",
          type:"number"
        },
      ],
      rows: [],

      userData: this.$cookies.get("user"),
      criteriaInfo: null,
      selectedCiteria: null,
      selectedAppointment: null,
      selectedStartDate: null,
      selectedEndDate: null,
      selectedData: null,
      responseContent: null,
      selectedAttId: null,
      darkModal: false,
      modalCreate: false,
      modalEdit: false,
      modalAlert: false,
      msgAlert: null,
      pictUrl1: "-",
      pictUrl2: "-",
      pictUrl3: "-",
      pictUrl4: "-",
      fileLink1: "-",
      fileLink2: "-",
      fileLink3: "-",
      fileLink4: "-",
      pic1New: null,
      pic2New: null,
      pic3New: null,
      pic4New: null,
      file1New: null,
      file2New: null,
      file3New: null,
      file4New: null,
    };
  },
  mounted() {},
  methods: {
    getData() {
      if (!this.$store.state.selectedSemester) {
        alert("โปรดระบุภาคเรียน");
        return false;
      }

      this.rows = null;

      let formData = new FormData();
      formData.append("txtAction", "getMissBySemesterSubject");
      formData.append("semesterId", this.$store.state.selectedSemester.code);
      formData.append("subjectId", this.$store.state.selectedSubject.code);
      formData.append("reasonCode", this.$store.state.selectedCriteria.code);

      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.rows = response.data.mainData;
        });
    },
    openModal(attId) {
      this.darkModal = true;
      this.selectedAttId = attId;
      //FETCH SELECTED RECORD
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&txtAttId=" +
            this.selectedAttId
        )
        .then((response) => {
          //console.log(response.data);
          this.selectedData = response.data.mainData;
          this.selectedCiteria = { code: this.selectedData.tc_citeria_no };
          this.$store.commit("setSelectedCriteria", {
            code: response.data.mainData.tc_citeria_no,
            label: response.data.mainData.tc_citeria_name,
          });
        })
        .finally(() => {});
      //FETCH CITERIA DATA
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&qCiteria=all"
        )
        .then((response) => {
          this.criteriaInfo = response.data.mainData;
        })
        .finally(() => {});
    },
    updateData() {
      const form = document.getElementById("formUpdateAbs");
      //console.log(form);
      const formData = new FormData(form);
      formData.append("txtAction", "update");
      formData.append("sltCiteria", this.$store.state.selectedCriteria.code);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.updateState == "updated") {
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            alert(response.data.error);
          }
        });
    },
    setSelected(value) {
      this.selectedCiteria = value;
    },
    removeAtt(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        let formData = new FormData();
        formData.append("txtAction", "delete");
        formData.append("attId", value);
        formData.append("userId", this.userData.user_id);
        //console.log(formData);
        this.axios
          .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.deleteState == "deleted") {
              alert("บันทึกสำเร็จ");
              location.reload();
            } else {
              alert(response.data.error);
            }
          });
      } else {
        return false;
      }
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
  },
};
</script>
