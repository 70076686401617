<template>
  <div class="relative w-full mb-3">
    <div v-if="provinceInfo">
      เลือกอำเภอ:
      <v-select
        :options="provinceInfo"
        v-model="$store.state.selectedProvince"
        @input="mutateSelectedProvince"
      >
        <template #search="{ attributes, events }">
          <input
            class="from-control ui-input-text"
            v-bind="attributes"
            v-on="events"
          />
        </template>
      </v-select>
      <div v-if="districtInfo">
        เลือกตำบล:
        <v-select
          :options="districtInfo"
          v-model="$store.state.selectedDistrict"
          @input="mutateSelectedDistrict"
        >
          <template #search="{ attributes, events }">
            <input
              class="from-control ui-input-text"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </v-select>
      </div>
    </div>
    <div v-else>loading...</div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    vSelect,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      provinceInfo: null,
      districtInfo: null,
      responseContent: null,
      showModal: false,
      modalSuccess: null,
      newCiteria: null,
      darkModal: false,
    };
  },
  mounted() {
    //SET USER DATA
    this.userData = this.$cookies.get("user");

    //FETCH AMPHUR
      this.axios
        .get(
          this.$hostSQL_Url +
            "beamingschoolAPI/sqlsrvAmphurAPI.php?txtAction=getAmphureDropDown&provinceName=ศรีสะเกษ"
        )
        .then((response) => {
          console.log(response.data);
          
        })
        .finally(() => {});

    //FETCH PROVINCE
    this.axios
      .get(
        this.$hostUrl + "php_action/provinceAPI.php?txtAction=getAllProvince"
      )
      .then((response) => {
        
        this.provinceInfo = response.data.dropdown;
        
        //console.log(response.data);
      })
      .finally(() => {});
  },
  methods: {
    mutateSelectedProvince(value) {
      this.$store.commit("setSelectedProvince", value);
      this.$store.commit("setSelectedDistrict", null);
      this.districtInfo = null;
      //FETCH District
      this.axios
        .get(
          this.$hostUrl +
            "php_action/districtAPI.php?txtAction=getDistrictByProvince&provinceCode=" +
            this.$store.state.selectedProvince.code
        )
        .then((response) => {
          //console.log(response.data);
          this.districtInfo = response.data.dropdown;
          //this.$store.commit("setProvinceDropdown", response.data.dropdown);
          //console.log(response.data);
        })
        .finally(() => {});
    },
    mutateSelectedDistrict(value) {
      this.$store.commit("setSelectedDistrict", value);
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
    openAddingSubject() {
      this.darkModal = true;
    },
    addSubject() {
      console.log(this.selectedStudent);
      const form = document.getElementById("formCreateSubject");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      this.axios
        .post(this.$hostUrl + "php_action/subjectAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.error == null) {
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
            location.reload();
          }
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-width: 0px;
  border: none;
  width: 20%;
}
</style>
