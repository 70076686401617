<template>
  <div class="relative w-full mb-3" v-if="semesterInfo">
    <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
      เลือกภาคเรียน:
    </label>
    <v-select :options="semesterInfo" v-model="$store.state.selectedSemester" @input="mutateSelectedSemester">
      <template #search="{ attributes, events }">
        <input class="from-control ui-input-text" v-bind="attributes" v-on="events" />
      </template>
    </v-select>

  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    vSelect,
  },
  data() {
    return {
      semesterInfo: null,
      userData: this.$cookies.get("user"),
      responseContent: null,
      showModal: false,
      modalSuccess: null,
      newCiteria: null,
      darkModal: false,
    };
  },
  mounted() {
    this.semesterInfo = this.fetchSemesterData();
  },
  methods: {
    fetchSemesterData() {
      //FETCH SEMESTER DATA
      let formData = new FormData();
      formData.append('txtAction', 'get');
      formData.append('qSemester', 'all');
      this.axios
        .post(
          this.$hostUrl + "php_action/semesterAPI.php", formData
        )
        .then((response) => {
          return response.data.mainData;
        });
    },
    mutateSelectedSemester(value) {
      this.$store.commit("setSelectedSemester", value);
    },

    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
    openAddingSubject() {
      this.darkModal = true;
    },
    addSubject(event) {
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      this.axios
        .post(this.$hostUrl + "php_action/subjectAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.createState == "created") {
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            alert(response.data.error);
          }
        });
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-width: 0px;
  border: none;
  width: 10%;
}
</style>
