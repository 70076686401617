<template>
  <CCard>
    <CCardHeader class="bg-yellow text-black"
      >ผลการเรียนจำแนกตามรายวิชา</CCardHeader
    >
    <CCardBody v-if="rows">
      <SltBatch />
      <div class="text-right" style="margin-bottom: 20px">
        <button class="btn btn-yellow" @click="getEnrollment">ค้นหา</button>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{ enabled: true }"
        :sort-options="{
          enabled: true,
          initialSortBy: [
            { field: 'level_abv_name', type: 'asc' },
            { field: 'std_class', type: 'asc' },
          ],
        }"
        :pagination-options="{ enabled: true, mode: 'page', perPage: 10 }"
      >
        >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'stud_id'">
            <router-link :to="'/statByStudent/' + props.row.stud_id">
              {{ props.row.stud_id }}
            </router-link>
          </span>
          <span v-else-if="props.column.field == 'actions'">
            <CDropdown>
              <template #toggler>
                <CHeaderNavLink>
                  <button class="btn btn-secondary btn-block">Tools</button>
                </CHeaderNavLink>
              </template>



              <CDropdownItem
                class="bg-danger text-white"
                v-if="props.row.enroll_who_create == parseInt(userData.user_id)"
              >
                <div
                  class="text-center"
                  style="width: 100%"
                  @click="removeEnrollment(props.row.enroll_id)"
                >
                  delete
                </div>
              </CDropdownItem>
            </CDropdown>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
      <CRow style="margin-top: 20px">
        <CCol
          ><div style="text-align: right">
            <button @click="onExport" class="btn btn-secondary">Export</button>
            <!-- เพิ่มปุ่ม Export -->
          </div></CCol
        >
      </CRow>
    </CCardBody>
    <div v-else class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </CCard>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx;
import SltSubject from "./SltSubject.vue";
import SltStudent from "./SltStudent.vue";
import SltBatch from "./SltBatch.vue";
import ChkPeriod from "./ChkPeriod.vue";

export default {
  name: "Dashboard",
  components: {
    VueGoodTable,
    Datepicker,
    vSelect,
    XLSX,
    SltSubject,
    SltStudent,
    SltBatch,
    ChkPeriod,
  },
  data() {
    return {
      columns: [
        {
          label: "#",
          field: "enroll_id",
        },
        {
          label: "เลขประจำตัวนักเรียน",
          field: "stud_id",
          type: "number",
        },
        {
          label: "ชื่อ",
          field: "std_name",
          type: "text",
        },
        {
          label: "นามสกุล",
          field: "std_surname",
          type: "text",
        },
        {
          label: "ชั้น",
          field: "level_abv_name",
          type: "text",
        },
        {
          label: "ห้อง",
          field: "std_class",
          type: "number",
        },
        {
          label: "ก่อน",
          field: "enroll_before",
          type: "number",
        },
        {
          label: "กลางภาค",
          field: "enroll_mid",
          type: "number",
        },
        {
          label: "หลัง",
          field: "enroll_after",
          type: "number",
        },
        {
          label: "ปลายภาค",
          field: "enroll_fin",
          type: "number",
        },
        {
          label: "รวม (คะแนน)",
          field: "enroll_sum",
          type: "number",
        },
        {
          label: "เวลาเรียนเมื่อจบภาคเรียน (ร้อยละ)",
          field: "percentAttend",
          type: "number",
        },
      ],
      rows: [],
      rowsExcel: null,
      userData: this.$cookies.get("user"),
      selectedRow: null,
      responseContent: null,
      darkModal: false,
      modalCreate: false,
      modalEdit: false,
      modalAlert: false,
      msgAlert: null,
      pictUrl1: "-",
      pictUrl2: "-",
      pictUrl3: "-",
      pictUrl4: "-",
      fileLink1: "-",
      fileLink2: "-",
      fileLink3: "-",
      fileLink4: "-",
      pic1New: null,
      pic2New: null,
      pic3New: null,
      pic4New: null,
      file1New: null,
      file2New: null,
      file3New: null,
      file4New: null,
      
    };
  },
  mounted() {
    //console.log(this.userData);
  },
  methods: {
    getEnrollment() {
      let formData = new FormData();
      formData.append("txtAction", "getEnrollemtByBatch");
      formData.append("batchId", this.$store.state.selectedBatch.code);
      //FETCH SELECTED RECORD
      this.axios
        .post(this.$hostUrl + "php_action/enrollmentAPI.php", formData)
        .then((response) => {
          //console.log(response.data);
          this.rows = response.data.mainData;
          this.rowsExcel = response.data.dataExcel;
        });
    },
    updateData() {
      //SEND DATA
      const form = document.getElementById("formUpdateAbs");
      const formData = new FormData(form);
      formData.append("txtAction", "update");
      formData.append("sltCiteria", this.selectedCiteria.code);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == false) {
            this.responseContent = response.data.error;
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
          }
        });

    },
    setSelected(value) {
      this.selectedCiteria = value;
    },
    removeEnrollment(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        let formData = new FormData();
        formData.append('txtAction', 'delete');
        formData.append('enrollId', value);
        formData.append('userId', this.userData.user_id);
        this.axios
          .post(
            this.$hostUrl +
              "php_action/enrollmentAPI.php", formData
          )
          .then((response) => {
            //console.log(response.data);
            if(response.data.deleteState == 'deleted'){
              alert("บันทึกสำเร็จ");
              location.reload();
            }
            else{
              alert(response.data.error);
            }
          });
      } else {
        return true;
      }
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rowsExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
  },
};
</script>
