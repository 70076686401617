<template>
  <CCard>
    <CCardHeader class="bg-yellow text-black">
      <h5>Upload คะแนน</h5>
    </CCardHeader>

    <form id="formUploadTransaction" v-on:submit.prevent="sendFile" method="POST" v-if="formType == 'excel'">
      <CCardBody>
        <CRow>
          <CCol lg="12">
            <SltBatch />
          </CCol>
          <CCol lg="12">
            <div class="dropbox">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                เลือก File ปพ.5 (.xlsx เท่านั้น): <a
                  href="https://deepmoe-my.sharepoint.com/:x:/g/personal/jettnipith_t_obec_moe_go_th/EQguIz1Q8_9Ao_MnmhuDsosBFa2WGrMXIKkNzdx7ucxODA?e=JdawQO">template</a>
              </label>
              <input type="file" class="form-control" name="file" required />
            </div>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter class="text-right">
        <button class="btn btn-yellow" :disabled="disableBtn">
          <span v-if="disableBtn == false">ส่ง</span><span v-else>Sending...</span>
        </button>
      </CCardFooter>
    </form>
    <form id="formUploadCSV" v-on:submit.prevent="sendFileCSV" method="POST" v-else>
      <CCardBody>
        <CRow>
          <CCol lg="12">
            <SltBatch />
          </CCol>
          <CCol lg="12">
            <div class="dropbox">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                เลือก File ปพ.5 (.csv เท่านั้น):
              </label>
              <input type="file" class="form-control" name="file" required />
            </div>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter class="text-right">
        <button class="btn btn-yellow" :disabled="disableBtn">
          <span v-if="disableBtn == false">ส่ง</span><span v-else>Sending...</span>
        </button>
      </CCardFooter>
    </form>
    <CCardFooter> </CCardFooter>

    <!--MODAL CREATE SUBJECT---------------------------------------------------------------------------->
    <form id="formCreateSubject" v-on:submit.prevent="addSubject" method="POST">
      <CModal :show.sync="darkModal" :no-close-on-backdrop="true" :centered="true" title="Modal title 2" size="lg"
        color="dark">
        <CRow>
          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                รหัสโรงเรียน
              </label>
              <input type="text" name="txtSchoolId" class="form-control" readonly :value="userData.user_address" />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="6">
            <div class="relative w-full mb-3" v-if="userData">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                รหัสวิชา/ รหัสสถานที่
              </label>
              <input type="text" name="txtSubjectCode" class="form-control" aria-label="Username"
                aria-describedby="basic-addon1" required />
            </div>
          </CCol>
          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                ชื่อวิชา/ สถานที่พบเจอ
              </label>
              <input type="text" name="txtSubjectName" class="form-control" required />
            </div>
          </CCol>
        </CRow>

        <template #header>
          <h6 class="modal-title">เพิ่มรายวิชา</h6>
          <CButtonClose @click="darkModal = false" class="text-white" />
        </template>
        <template #footer>
          <button type="submit" class="btn btn-success">Save</button>
          <CButton @click="darkModal = false" color="danger">Discard</CButton>
        </template>
      </CModal>
    </form>
  </CCard>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import SltBatch from "./SltBatch";
export default {
  components: {
    Datepicker,
    vSelect,
    SltBatch,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      responseContent: null,
      showModal: false,
      modalSuccess: null,
      newCiteria: null,
      darkModal: false,
      qrString: null,
      disableBtn: false,
      formType: 'excel'
    };
  },
  mounted() {
    if (this.userData.user_address == '1040051091' || this.userData.user_address == '1040051128') {
      this.formType = 'csv';
    }
  },
  methods: {
    sendFile(event) {
      if (
        this.$store.state.selectedBatch.length == 0 ||
        this.$store.state.selectedBatch == ""
      ) {
        alert("กรุณาเลือกรายวิชา");
        return false;
      }
      this.disableBtn = true;
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("batchId", this.$store.state.selectedBatch.code);
      formData.append("userId", this.userData.user_id);
      this.axios
        .post(this.$hostUrl + "php_action//import_ExcelEnroll.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.disableBtn = false;
          console.log(response.data);
          if (response.data.updatedRows || response.data.insertedRows) {
            let inserted = response.data.insertedRows
              ? response.data.insertedRows.length
              : 0;
            let updated = response.data.updatedRows
              ? response.data.updatedRows.length
              : 0;
            let err = response.data.error ? response.data.error : "-";
            alert(
              "เพิ่มข้อมูล: " +
              inserted +
              " รายการ\n" +
              "อัพเดท: " +
              updated +
              " รายการ\n" +
              "error:" +
              err
            );
          }
          location.reload();
        });
    },
    sendFileCSV(event) {
      if (
        this.$store.state.selectedBatch.length == 0 ||
        this.$store.state.selectedBatch == ""
      ) {
        alert("กรุณาเลือกรายวิชา");
        return false;
      }
      this.disableBtn = true;
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("batchId", this.$store.state.selectedBatch.code);
      formData.append("userId", this.userData.user_id);
      this.axios
        .post(this.$hostUrl + "php_action//import_csvEnroll_KNW.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.disableBtn = false;
          console.log(response.data);
          if (response.data.updatedRows || response.data.insertedRows) {
            let inserted = response.data.insertedRows
              ? response.data.insertedRows.length
              : 0;
            let updated = response.data.updatedRows
              ? response.data.updatedRows.length
              : 0;
            let err = response.data.error ? response.data.error : "-";
            alert(
              "เพิ่มข้อมูล: " +
              inserted +
              " รายการ\n" +
              "อัพเดท: " +
              updated +
              " รายการ\n" +
              "error:" +
              err
            );
          }
          location.reload();
        });
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
