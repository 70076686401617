<template>
  <div class="relative w-full mb-3">
    <div v-if="batchInfo">
      เลือกภาคเรียน และรายวิชา:
      <v-select :options="batchInfo" v-model="$store.state.selectedBatch" @input="mutateSelectedBatch">
        <template #search="{ attributes, events }">
          <input class="from-control ui-input-text" v-bind="attributes" v-on="events" />
        </template>
      </v-select>

      <div class="text-right">
        <button type="button" @click="openModalAddBatch()" color="link" class="btn btn-link">
          <i class="ri-add-box-line"></i>
        </button>
      </div>
    </div>
    <div v-else>loading...</div>
    <!---------MODAL ADD BATCH------------>
    <form id="formCreateBatch" v-on:submit.prevent="addBatch" method="POST">
      <CModal :show.sync="ModalAddBatch" :no-close-on-backdrop="true" :centered="true" title="Modal title 2" size="lg"
        color="dark">
        <CRow>
          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                รหัสโรงเรียน:
              </label>
              <input type="text" name="schoolCode" class="form-control" readonly :value="userData.user_address" />
            </div>
          </CCol>
          <CCol lg="6" v-if="userData">

          </CCol>
          <CCol lg="6" v-if="userData">
            <SltSemester />
          </CCol>
          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                จำนวนชั่วโมง:
              </label>
              <input type="number" name="duration" class="form-control" required />
            </div>
          </CCol>
          <CCol lg="12" v-if="userData">
            <SltSubject />
          </CCol>

        </CRow>

        <template #header>
          <h6 class="modal-title">เพิ่มรายวิชาของท่าน</h6>
          <CButtonClose @click="ModalAddBatch = false" class="text-white" />
        </template>
        <template #footer>
          <button type="submit" class="btn btn-yellow">Save</button>
        </template>
      </CModal>
    </form>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import SltSemester from "./SltSemester";
import SltSubject from "./SltSubject";
export default {
  components: {
    Datepicker,
    vSelect,
    SltSemester,
    SltSubject,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      modalSuccess: null,
      newCiteria: null,
      ModalAddBatch: false,
      batchInfo: null,
    };
  },
  mounted() {
    //FETCH ASSET
    let formData = new FormData();
    formData.append("txtAction", "getBatchByTeacher");
    formData.append("teacherId", this.userData.user_id);

    this.axios
      .post(this.$hostUrl + "php_action/batchAPI.php", formData)
      .then((response) => {
        //console.log(response.data);
        this.batchInfo = response.data.mainData;
      });
  },
  methods: {
    mutateSelectedBatch(value) {
      this.$store.commit("setSelectedBatch", value);
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },

    addBatch(event) {
      //alert(555);
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      formData.append("semesterId", this.$store.state.selectedSemester.code);
      formData.append("teacherId", this.userData.user_id);
      formData.append("userId", this.userData.user_id);
      formData.append("subjects", this.$store.state.selectedSubject.code);
      this.axios
        .post(this.$hostUrl + "php_action/batchAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.createState == "created") {
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            alert(response.data.error);
          }
        });
    },
    openModalAddBatch() {
      this.ModalAddBatch = true;
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-width: 0px;
  border: none;
  width: 20%;
}
</style>
